@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Young+Serif&display=swap');

/* @import '~rsuite/dist/rsuite.min.css'; */

html, body, #root {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif !important;
}

body {
  overflow: hidden;
}

p, a, ul, li, button, input, textarea, select, h1, h2, h3, h4, h5, h6 {
  font-family: "Inter", sans-serif !important;
  color: inherit;
}

a {
  text-decoration: none;
}

p {
  font-size: 13px;
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

b {
  font-weight: 500;
}

ul {
  padding-left: 16px;
}

ul li {
  margin-bottom: 8px;
}
ul li:last-child {
  margin: 0;
}































